import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JsBarcode from 'jsbarcode';
import Logo from '../assets/Logo.png';
import Signature from '../assets/signature.jpeg';

export const downloadInvoice = async (orders,total, deliveryCharge, grandTotal) => {
    const currentDate = new Date().toLocaleDateString();

    const barcodeData = `${orders.order.order_id}`;
    const barcodeCanvas = document.createElement('canvas');
    JsBarcode(barcodeCanvas, barcodeData, { format: "CODE128" }); 
    const barcodeBase64 = barcodeCanvas.toDataURL();

    const invoiceHtml = `
      <div id="invoice-content" style="font-family: Arial, sans-serif; padding: 20px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="842" bgcolor="#f5f5f5" style="border-collapse: collapse; line-height:18px; font-size:13px; position:relative;">
          <tr>
            <td>
              <table align="center" border="0" cellpadding="0" cellspacing="0" width="842" bgcolor="#fff" style="border-collapse: collapse; padding:20px;">
                <tr>
                  <td align="left">
                    <h1 style="margin:0;">Invoice</h1>
                    <p>Invoice No: ${orders.order.invoice_id}</p>
                    <p>Date: ${currentDate}</p>
                  </td>
                  <td align="right">
                    <img src="${Logo}" alt="Company Logo" style="width:100px;"/>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table align="center" border="1" cellpadding="10" cellspacing="0" width="842" bgcolor="#fff" style="border-collapse: collapse;">
                <tr>
                  <td colspan="2" style="padding:15px;"><b>Shipping / Billing Address</b></td>
                </tr>
                <tr>
                  <td width="50%" style="border-right: 1px solid gray;">
                    <table style="padding-left:10px;padding-right:10px;">
                      <tr><td>${orders.order.customer_name}</td></tr>
                      <tr><td>${orders.order.address},</td></tr>
                      <tr><td>${orders.order.city}, ${orders.order.state},</td></tr>
                      <tr><td>${orders.order.country}, ${orders.order.postalCode}.</td></tr>
                      <tr><td><b>Email:</b> ${orders.order.email}</td></tr>
                      <tr><td><b>Phone:</b> ${orders.order.dialcode}${orders.order.mobile}</td></tr>
                      <tr><td><b>Date:</b> ${orders.order.ordered_date}</td></tr>
                    </table>
                  </td>
                  <td align="center">
                    <img src="${barcodeBase64}" alt="Invoice Barcode" style="width:150px; height:auto;" />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table align="center" border="1" cellpadding="5" cellspacing="0" width="842" bgcolor="#fff" style="border-collapse: collapse;">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Sub Total</th>
                  </tr>
                </thead>
                <tbody>
                  ${orders.products.map(product => `
                    <tr>
                      <td>${product.name}</td>
                      <td>${product.quantity}</td>
                      <td>${product.price}</td>
                      <td>${product.quantity * product.price} ${orders.order.currency}</td>
                      </tr>
                  `).join('')}
                  <tr>
                    <td colspan="3" align="right"><b>Total</b></td>
                    <td>${total} ${orders.order.currency}</td>
                  </tr>
                  <tr>
                    <td colspan="3" align="right"><b>Delivery Charge</b></td>
                    <td>${deliveryCharge} ${orders.order.currency}</td>
                  </tr>
                  <tr>
                    <td colspan="3" align="right"><b>Grand Total</b></td>
                    <td>${grandTotal} ${orders.order.currency}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table align="center" cellpadding="10" cellspacing="0" width="842" bgcolor="#fff" style="border-collapse: collapse;">
                <tr>
                  <td width="50%" style="padding:10px;">
                    <b>Company Address:</b>
                    <p>WALLFLEUR<br>
                    H/no.-76 Forest Colony<br>
                    Dimapur, 797112,<br>
                    Nagaland, India<br>
                    6391113851</p>
                  </td>
                  <td width="50%" align="right">
                    <p><b>Authorized Signature:</b></p>
                    <img src="${Signature}" alt="Signature" style="width:150px;"/>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style="background-color:#fff !important;">
            <td>
              <table style="margin-top:50px;" align="center" cellpadding="10" cellspacing="0" width="842" bgcolor="#fff" style="border-collapse: collapse;">
              <th  style="font-size:10px;text-align:left;">Terms & Conditions</th>
              <tr style="background-color:#fff !important;">
                <td style="font-size:8px;padding:5px;">1. All sales are final. Due to handmade nature of our products, we do not offer refunds, cancellations or replacements.</td>
              </tr>
              <tr style="background-color:#fff !important;">
                <td style="font-size:8px;padding:5px;">2. This is a preorder item. Please note, the standard processing time is up to 4 weeks from the date of payment</td>
              </tr>              
              <tr style="background-color:#fff !important;">
                <td style="font-size:8px;padding:5px;">3. A tracking number will be provided once the order has been shipped.</td>
              </tr>              
              <tr style="background-color:#fff !important;">
                <td style="font-size:8px;padding:5px;">4. We shall not be liable for any delay in delivery or lost packages.</td>
              </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    `;

    const hiddenContainer = document.createElement('div');
    hiddenContainer.style.position = 'fixed';
    hiddenContainer.style.top = '-10000px';
    hiddenContainer.innerHTML = invoiceHtml;
    document.body.appendChild(hiddenContainer);

    try {
        const canvas = await html2canvas(hiddenContainer);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        pdf.save(`invoice_wallfleurthings.pdf`);
    } catch (error) {
        console.error("Error generating PDF:", error);
    } finally {
        document.body.removeChild(hiddenContainer);
    }
};

import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { DEFAULT_IMAGE,COMMON,PRODUCT } from '../constant/constant';

export default function CategoryProduct({ cardData,countryCurrency, contentType }) {
  const navigate = useNavigate();

  const handleRedirectUrlClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <>
      <div className="product-card">
        {cardData.map((card) => (
          <div key={card._id} className="prod-card"  onClick={() => handleRedirectUrlClick(`/${contentType}/${card.slug}`)}>
          {card.preorder == '1' && (
            <div className="preorder-badge">
              <img src={`${COMMON}preorder-badge.png`} alt="preorder-badge" />
            </div>
            )}
          <div className={`prod-img ${card.quantity <= 2 || card.coming_soon === 1 ? 'sold_out' : ''}`}>
              <img className="card-top lazyload" src={`${DEFAULT_IMAGE}`} data-src={`${PRODUCT}${card.image1}`} alt={`Card ${card._id} cap`} />
              <span style={{ display: card.quantity <= 2 ? 'block' : 'none' }}>Out of Stock</span>
              <span style={{ display: card.coming_soon == 1 ? 'block' : 'none' }}>Coming soon</span>
          </div>
          <div className="prod-card-body category-pro">
            <div className="prod-content">
              <Link to={`/${contentType}/${card.slug}`} className="prod-name">{card.name}</Link>
              <p className="prod-price">{card.currency}{card.price}</p>
            </div>
          </div>
        </div>
        ))}
      </div>
    </>
  );
}
import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState(Cookies.get('selectedCountry') ?? 'India');

  const updateCountry = (country) => {
    if(selectedCountry != country){
      Cookies.remove('selectedCountry');
      Cookies.set('selectedCountry', country, { expires: 1 / 24 });
      setSelectedCountry(country);
    }
};
  return (
    <CountryContext.Provider value={{ selectedCountry, updateCountry }}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => {
  return useContext(CountryContext);
};

export default CountryContext;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CategoryProduct from '../components/CategoryProduct';
import MetaTags from '../utils/MetaTags';
import { useCountry } from '../context/CountryContext';
import {COMMON} from '../constant/constant';
import { Link,useParams } from 'react-router-dom';

export default function AllProducts() {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { selectedCountry,updateCountry } = useCountry();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}all-product`,{
            withCredentials: true,
        });
        setData(response.data.updatedResult);
        if (response.data.currency !== selectedCountry) {
          updateCountry(response.data.currency);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, [slug,selectedCountry]);

  if (loading) {
    return (
        <div className='catloader'>
          <img src={`${COMMON}wallfleur.loading.gif`} alt='loader' />
        </div> 
      );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <Navbar />
      {data && (
        <>
          <MetaTags 
            title='All Products'
            description='Explore our wide range of handcrafted clay products, uniquely designed to add charm and elegance to your space. Find the perfect piece for your collection today!' 
            noIndex={false}
          />
          <div className='wallfleur_row'>
            <div className='breadcrums'><Link to="/"> Home </Link> {' / All Products'} </div>
            <h1 className='heading-text'>All Products</h1>
            <div className='category-product-list'>
              <CategoryProduct cardData={data} countryCurrency={selectedCountry} contentType="product" />
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
}

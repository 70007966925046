import React from 'react';
import { Link } from 'react-router-dom';
import { CATEGORY } from '../constant/constant';

const BentoGrid = ({ datas, contentType }) => {
  return (
    <div className="bento-container">
      {datas.slice(0, 4).map((data, index) => (
        <Link 
          key={index} 
          to={`/${contentType}/${data.slug}`} 
          className={`bento-item ${index === 0 ? 'large' : ''}`} 
          style={{
            backgroundImage: `url(${CATEGORY}${data.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            textDecoration: 'none',
          }}
        >
          <div className="overlay">
            <p className="bento-name">{data.name}</p>
          </div>
        </Link>
      ))}
      
      {datas.length > 4 && (
        <Link to="/all-category" className="bento-item view-all">
          View all Categories
        </Link>
      )}
    </div>
  );
};

export default BentoGrid;

import React, { useState, useRef,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Card, Form, Alert } from 'react-bootstrap';
import eyeIcon from '../../src/assets/visible.png';

import { toast } from 'react-toastify';
import Navbar from '../components/Navbar';
import { useAuth } from '../context/authContext';
import MetaTags from '../utils/MetaTags';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

export default function AuthTransition() {

  const navigate = useNavigate();
  const [isRegister, setIsRegister] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    dialCode:'',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [registrationError, setRegistrationError] = useState('');
  const { user,login } = useAuth();
  const [otp, setOtp] = useState(['', '', '', '']);
  const [otpError, setOtpError] = useState('');
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showRegisterPassword, setShowRegisterPassword] = useState(false);
  const otpRefs = useRef([]);
  const itiRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const firstEmptyIndex = otp.findIndex(digit => digit === '');
    if (firstEmptyIndex !== -1 && otpRefs.current[firstEmptyIndex]) {
      otpRefs.current[firstEmptyIndex].focus();
    }
  }, [otp]);

  useEffect(() => {
    if(user){
      navigate('/');
    }
  }, [user]);
  

  useEffect(() => {
    if (isRegister) {
      if (inputRef.current) {
        itiRef.current = intlTelInput(inputRef.current, {
          initialCountry: 'in',
          preferredCountries: ['in', 'us'],
          separateDialCode: true,
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
        });
      }
    } else {

      if (itiRef.current) {
        itiRef.current.destroy();
        itiRef.current = null;
      }
    }
    
    return () => {
      if (itiRef.current) {
        itiRef.current.destroy();
        itiRef.current = null;
      }
    };
  }, [isRegister]);
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const errors = {};

    try {
        if (!formData.name.trim()) errors.name = 'Name is required';

        const dialCodeElement = document.getElementsByClassName('iti__selected-dial-code')[0];
        const dialCode = dialCodeElement ? dialCodeElement.innerText : '+91';
        const iti = itiRef.current;

        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone Number is required';
        } else if (/[^0-9]/.test(formData.phoneNumber)) {
          errors.phoneNumber = 'No white space or special characters allowed';
        } else if (!iti.isValidNumber()) {
            errors.phoneNumber = 'Invalid phone number';
        } else {
            formData.dialCode = dialCode;
        }
        
        if (!formData.email.trim()) errors.email = 'Email is required';
        if (!validateEmail(formData.email)) errors.email = 'Incorrect Email';
        if (!formData.password.trim()) errors.password = 'Password is required';
        if (formData.password.length < 8) errors.password = 'Password should have at least 8 characters';
        if (formData.password !== formData.confirmPassword) errors.confirmPassword = 'Passwords do not match';
        
        setErrors(errors);

        if (Object.keys(errors).length > 0) {
            return; 
        }

        setIsLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        });
        
        if (response.ok) {
            setRegistrationSuccess(true);
            setShowOtpForm(true);
            toast.success('Registration successful! Please check your email for the OTP.', { position: 'top-right' });
        } else {
            const errorData = await response.json();
            setRegistrationError(errorData.message);
        }
    } catch (error) {
        console.error('Error:', error);
        setRegistrationError('An error occurred. Please try again later.');
    } finally {
        setIsLoading(false);
    }
};


  const handleLogin = async (e) => {
    e.preventDefault();
    if (loginEmail === '' || loginPassword === '') {
      setValidationError('Fields cannot be left empty');
      return;
    }
    if (!validateEmail(loginEmail)) {
      setValidationError('Invalid email.');
      return;
    }else if(loginPassword.length < 8){
      setValidationError('Password should have at least 8 characters.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: loginEmail, password: loginPassword })
      });
      const data = await response.json();
      if (response.ok) {
        const { token } = data;
        login(token);
        toast.success('Logged in Successfully!', { position: 'top-right' });
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setValidationError(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setValidationError('An error occurred. Please try again later.');
    }
    setIsLoading(false);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (otp.some(digit => digit === '')) {
      setOtpError('OTP is required');
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}verify-otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: formData.email, otp: otp.join('') })
      });
      const data = await response.json();
      if (response.ok) {
        toast.success('Account verified successfully! You can now log in.', { position: 'top-right' });
        setShowOtpForm(false);
        setIsRegister(false);
        setFormData({
          name: '',
          phoneNumber: '',
          email: '',
          password: '',
          confirmPassword: ''
        });
        setOtp(['', '', '', '', '', '']); // Clear OTP fields
      } else {
        setOtpError(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setOtpError('An error occurred. Please try again later.');
    }
    setIsLoading(false);
  };

  const handleOtpChange = (index, e) => {
    const { value } = e.target;

    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otpRefs.current.length - 1 && value) {
        otpRefs.current[index + 1].focus();
      }
    } else if (e.nativeEvent.inputType === 'deleteContentBackward') {
      if (index > 0) {
        otpRefs.current[index - 1].focus();
      }
    }
  };

  const handleOtpKeyDown = (index, e) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        otpRefs.current[index - 1].focus();
      }
    }
  };

  const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.toLowerCase());

  return (
    <>
      <MetaTags 
        title={isRegister ? "Register - Wallfleur" : "Login - Wallfleur"} 
        description={isRegister ? "Register to join Wallfleur Family" : "Login to access your Wallfleur account"}
        keywords={isRegister ? "Register, Sign Up" : "Login, Sign In"}
        noIndex={true}
      />
      <Navbar />
      <Container className="auth-container">
        {!showOtpForm && (
          <div className="auth-form">
            {!isRegister ? (
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-2 text-uppercase login_heading">Login</h2>
                    {validationError && <Alert variant="danger">{validationError}</Alert>}
                    <Form onSubmit={handleLogin}>
                      <Form.Group className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email (Example: abc@abc.com)"
                          value={loginEmail}
                          onChange={(e) => setLoginEmail(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type={showLoginPassword ? "text" : "password"} 
                          placeholder="Password"
                          value={loginPassword}
                          onChange={(e) => setLoginPassword(e.target.value)}
                          required
                          className='password'
                        />
                      </Form.Group>
                      <div style={{ marginTop: '10px',marginBottom:'10px',display:'flex',justifyContent:'space-between'}}>
                        <label>
                        <input
                            type="checkbox"
                            checked={showLoginPassword}
                            onChange={() => setShowLoginPassword(!showLoginPassword)} 
                          />
                          Show Password
                        </label>
                        <a href="/forgotpassword">Forgot password?</a>
                      </div>
                      <p className='terms-condition'>By clicking Login you agree to our Privacy Policy and Terms and Services</p>
                      <Button className="w-100 login_submit" variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? 'Logging in...' : 'Login'}
                      </Button>
                      <div className="mt-3 text-center sign-in-up">
                        <p>
                          Don't have an account?{' '}
                          <span onClick={() => setIsRegister(true)} className="text-primary fw-bold">
                            Sign Up
                          </span>
                        </p>
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h4 className="fw-bold mb-2 text-uppercase login_heading">Register</h4>
                    {registrationError && <p className="text-danger mt-2">{registrationError}</p>}
                    <Form onSubmit={handleRegister}>
                      <Form.Group className="mb-4">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        {errors.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="tel"
                          ref={inputRef}
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                          required
                      />
                        {errors.phoneNumber && <Form.Text className="text-danger">{errors.phoneNumber}</Form.Text>}
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter Email (Example: abc@abc.com)"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
                      </Form.Group>
                      <Form.Group className="mb-4">
                      <Form.Label>Password</Form.Label>
                      <div style={{ position: 'relative' }}>
                        <Form.Control
                          type={showRegisterPassword ? "text" : "password"} 
                          name="password"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                          required
                        />
                        <img
                          src={eyeIcon} 
                          alt="Show Password"
                          onClick={() => setShowRegisterPassword(!showRegisterPassword)}
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            width: '25px',
                          }}
                        />
                      </div>
                      {errors.password && <Form.Text className="text-danger">{errors.password}</Form.Text>}
                    </Form.Group>
                      <Form.Group className="mb-5">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          required
                        />
                        {errors.confirmPassword && <Form.Text className="text-danger">{errors.confirmPassword}</Form.Text>}
                      </Form.Group>
                      <p className='terms-condition'>By clicking Register you agree to our Privacy Policy and Terms and Services</p>
                      <Button className="w-100 login_submit" variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? 'Registering...' : 'Register'}
                      </Button>
                      <div className="mt-3 text-center sign-in-up">
                        <p>
                          Already have an account?{' '}
                          <span onClick={() => setIsRegister(false)} className="text-primary fw-bold">
                            Login
                          </span>
                        </p>
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            )}
          </div>
        )}
        {showOtpForm && (
          <div className="auth-form">
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h4 className="fw-bold mb-2 text-uppercase login_heading">Verify OTP</h4>
                  {otpError && <Alert variant="danger">{otpError}</Alert>}
                  <Form onSubmit={handleVerifyOtp}>
                    <Form.Group className="otp-input-parent">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="tel"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleOtpChange(index, e)}
                          onKeyDown={(e) => handleOtpKeyDown(index, e)}
                          ref={(ref) => (otpRefs.current[index] = ref)}
                          className="otp-input"
                        />
                      ))}
                    </Form.Group>
                    <div className='verify_btn'>
                      <Button className="verify_otp" variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? 'Verifying...' : 'Verify OTP'}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
      </Container>
    </>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Alert } from "react-bootstrap";
import { toast } from 'react-toastify';
import Logo from '../assets/Logo.png';
import eyeIcon from '../../src/assets/visible.png';
import { useAuth } from "../context/authContext";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

const LoginModal = ({ onClose }) => {
    const [email, setEmail] = useState("");
    const [showRegisterPassword, setShowRegisterPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [validationError, setValidationError] = useState("");
    const { login } = useAuth();
    const [isLogin, setIsLogin] = useState(true);
    const [isRegister, setIsRegister] = useState(false);
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
        email: "",
        password: "",
        confirmPassword: ""
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [registrationError, setRegistrationError] = useState("");
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpError, setOtpError] = useState("");
    const otpRefs = useRef([]);
    const itiRef = useRef(null);
    const inputRef = useRef(null);

    const handleToggleForm = () => {
        setIsLogin(prevIsLogin => !prevIsLogin);
        setIsRegister(prevIsRegister => !prevIsRegister);
    };

    useEffect(() => {
        if (isRegister) {
          if (inputRef.current) {
            itiRef.current = intlTelInput(inputRef.current, {
              initialCountry: 'in',
              preferredCountries: ['in', 'us'],
              separateDialCode: true,
              utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
            });
          }
        } else {

          if (itiRef.current) {
            itiRef.current.destroy();
            itiRef.current = null;
          }
        }
        
        return () => {
          if (itiRef.current) {
            itiRef.current.destroy();
            itiRef.current = null;
          }
        };
      }, [isRegister]);

    const handleCloseModal = () => {
        onClose();
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        if (email === '' || password === '') {
            setValidationError("Fields cannot be left empty");
            return;
        }

        if (!validateEmail(email) || password.length < 8) {
            setValidationError("Invalid email or password. Please check your inputs.");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();
            if (response.ok) {
                const { token } = data;
                login(token);
                handleCloseModal();
            } else {
                setValidationError(data.message);
            }
        } catch (error) {
            console.error("Error:", error);
            setValidationError("An error occurred. Please try again later.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = {};
        if (!formData.name.trim()) {
            errors.name = "Name is required";
        }

        const dialCodeElement = document.getElementsByClassName('iti__selected-dial-code')[0];
        const dialCode = dialCodeElement ? dialCodeElement.innerText : '+91';
        const iti = itiRef.current;

        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone Number is required';
        } else if (/[^0-9]/.test(formData.phoneNumber)) {
            errors.phoneNumber = 'No white space or special characters allowed';
        } else if (!iti.isValidNumber()) {
            errors.phoneNumber = 'Invalid phone number';
        } else {
            formData.dialCode = dialCode;
        }

        if (!formData.email.trim()) {
            errors.email = "Email is required";
        }
        if (!validateEmail(formData.email)) {
            errors.email = "Incorrect Email";
        }
        if (!formData.password.trim()) {
            errors.password = "Password is required";
        }
        if (formData.password.length < 8) {
            errors.password = "Password should be at least 8 characters";
        }
        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
        }
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}register`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formData)
                });
                if (response.ok) {
                    setIsRegister(false);
                    setIsLogin(false);
                } else {
                    const errorData = await response.json();
                    setRegistrationError(errorData.message);
                }
            } catch (error) {
                console.error("Error:", error);
            }
            setIsLoading(false);
        }
    };

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setValidationError('');
        setRegistrationError('');
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        if (otp.some(digit => digit === '')) {
            setOtpError('OTP is required');
            return;
        }
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}verify-otp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: formData.email, otp: otp.join('') })
            });
            const data = await response.json();
            if (response.ok) {
                toast.success('Account verified successfully! You can now log in.', { position: 'top-right' });
                setFormData({
                    name: '',
                    phoneNumber: '',
                    email: '',
                    password: '',
                    confirmPassword: ''
                });
                setOtp(['', '', '', '', '', '']);
                handleToggleForm();
            } else {
                setOtpError(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            setOtpError('An error occurred. Please try again later.');
        }
        setIsLoading(false);
    };

    const handleOtpChange = (index, e) => {
        const { value } = e.target;

        if (/^[0-9]?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < otpRefs.current.length - 1 && value) {
                otpRefs.current[index + 1].focus();
            }
        } else if (e.nativeEvent.inputType === 'deleteContentBackward') {
            if (index > 0) {
                otpRefs.current[index - 1].focus();
            }
        }
    };

    const handleOtpKeyDown = (index, e) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                otpRefs.current[index - 1].focus();
            }
        }
    };

    return (
        <div>
        <div className="loginmodal">
            <div className="modal-content">
                <button className="close-button" onClick={handleCloseModal}>X</button>

                <img src={Logo} style={{ "height": "3rem", "width": "7rem" }} alt="Logo" />
                <div className='login-content'>
                    {isLogin ? (
                        <div className="Login mb-3">
                            {validationError && <Alert variant="danger">{validationError}</Alert>}
                            <Form onSubmit={handleLogin}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control
                                        type={showLoginPassword ? "text" : "password"} 
                                        placeholder="Password"
                                        className="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <div style={{ marginTop: '10px',marginBottom:'10px',display:'flex',justifyContent:'space-between', fontSize:'12px'}}>
                                    <label>
                                    <input
                                        type="checkbox"
                                        checked={showLoginPassword}
                                        onChange={() => setShowLoginPassword(!showLoginPassword)} 
                                    />
                                    Show Password
                                    </label>
                                    <a href="/forgotpassword">Forgot password?</a>
                                </div>
                                <p className='terms-condition'>By clicking Login you agree to our Privacy Policy and Terms and Services</p>
                                <div className="md-3">
                                    <Button className="w-100 submit-btn" variant="primary" type="submit">
                                        Login
                                    </Button>
                                </div>
                            </Form>
                            <div className="mt-3">
                                <p className="mb-0 text-center sign-in-up">
                                    Don't have an account?{" "}
                                    <span className="text-primary fw-bold" onClick={handleToggleForm}>
                                        Sign Up
                                    </span>
                                </p>
                            </div>
                        </div>
                    ) : isRegister ? (
                        <div className="register mb-3">
                            {registrationError && <p className="text-danger mt-2">{registrationError}</p>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Control type="text" name="name" placeholder="Enter Name" value={formData.name} onChange={handleChange} required />
                                    {errors.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                                    <Form.Control type="tel" ref={inputRef} name="phoneNumber" placeholder="Enter Phone Number" value={formData.phoneNumber} onChange={handleChange} required />
                                    {errors.phoneNumber && <Form.Text className="text-danger">{errors.phoneNumber}</Form.Text>}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="email" name="email" placeholder="Enter email" value={formData.email} onChange={handleChange} required />
                                    {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                <div style={{ position: 'relative' }}>
                                    <Form.Control type={showRegisterPassword ? "text" : "password"} name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
                                    <img
                                        src={eyeIcon} 
                                        alt="Show Password"
                                        onClick={() => setShowRegisterPassword(!showRegisterPassword)}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            width: '25px',
                                        }}
                                        />                   
                                    </div>
                                    {errors.password && <Form.Text className="text-danger">{errors.password}</Form.Text>}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                                    <Form.Control type="password" name="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} required />
                                    {errors.confirmPassword && <Form.Text className="text-danger">{errors.confirmPassword}</Form.Text>}
                                </Form.Group>
                                <p className='terms-condition'>By clicking Register you agree to our Privacy Policy and Terms and Services</p>
                                <div className="md-3">
                                    <Button className="w-100 submit-btn" variant="primary" type="submit" disabled={isLoading}>
                                        {isLoading ? "Registering..." : "Register"}
                                    </Button>
                                </div>
                            </Form>
                            <div className="mt-3">
                                <p className="mb-0 text-center sign-in-up">
                                    Already have an account?{" "}
                                    <span className="text-primary fw-bold" onClick={handleToggleForm}>
                                        Sign In
                                    </span>
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h4 className="fw-bold mb-2 text-uppercase login_heading">Verify OTP</h4>
                            {otpError && <Alert variant="danger">{otpError}</Alert>}
                            <Form onSubmit={handleVerifyOtp}>
                                <Form.Group className="otp-input-parent">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="tel"
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleOtpChange(index, e)}
                                            onKeyDown={(e) => handleOtpKeyDown(index, e)}
                                            ref={(ref) => (otpRefs.current[index] = ref)}
                                            className="otp-input"
                                        />
                                    ))}
                                </Form.Group>
                                <div className='verify_btn'>
                                    <Button className="verify_otp" variant="primary" type="submit" disabled={isLoading}>
                                        {isLoading ? 'Verifying...' : 'Verify OTP'}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className="backdrop blur" />
    </div>
    );
};

export default LoginModal;

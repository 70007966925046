import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/authContext';
import { toast } from 'react-toastify';
import { ColorRing } from "react-loader-spinner";
import { useDispatch } from 'react-redux';
import { clearCart } from '../../redux/cartSlice';

export default function PaymentDetails({ cardData, userData, countryCurrency, handlePaymentComplete, reduceProductQuantity, restoreProductQuantities }) {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const dispatch = useDispatch();

    const productTotal = cardData.reduce((total, card) => total + card.price * card.quantity, 0);
    const deliveryCharge = countryCurrency === 'India'
        ? (productTotal >= 4000 ? 350 : 150)
        : (productTotal >= 130 ? 35 : productTotal < 20 ? 18 : 20);
    const totalAmount = productTotal + deliveryCharge;

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    const loadPaypalScript = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&currency=USD`;
            script.onload = () => resolve(true);
            script.onerror = () => reject(new Error('Failed to load PayPal script.'));
            document.body.appendChild(script);
        });
    };

    const handleRazorpayPayment = async () => {
        setLoading(true);
        const res = await loadRazorpayScript();
        if (!res) {
            toast.error('Razorpay SDK failed to load. Are you online?', { position: 'top-right' });
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}createOrder`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${user}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    amount: calculateTotal(),
                    userData,
                    products: cardData.map(product => ({
                        id: product.id,
                        quantity: product.quantity,
                        name: product.name
                    }))
                }),
                withCredentials: true 
            });

            const paymentData = await response.json();

            if (response.ok && paymentData.id) {

            await reduceProductQuantity();

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                amount: paymentData.amount,
                currency: countryCurrency === 'India' ? "INR" : "USD",
                name: "Wallfleur",
                description: "Test Transaction",
                image: "https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/Logo.png",
                order_id: paymentData.id,
                handler: async (response) => {
                    const result = await fetch(`${process.env.REACT_APP_API_URL}verifyPayment`, {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${user}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            order_id: paymentData.id,
                            payment_id: response.razorpay_payment_id,
                            signature: response.razorpay_signature
                        })
                    });

                    if (result.status === 200) {
                        toast.success('Payment successful!', { position: 'top-right' });
                        handlePaymentComplete();
                        dispatch(clearCart());
                    } else {
                        await restoreProductQuantities();
                        toast.error('Payment verification failed. Please try again.', { position: 'top-right' });
                    }
                    setLoading(false);
                },
                modal: {
                    ondismiss: () => {
                        restoreProductQuantities();
                        setLoading(false);
                        toast.info('Payment process was cancelled.', { position: 'top-right' });
                    }
                },
                prefill: {
                    name: userData.name,
                    email: userData.email,
                    contact: userData.mobile
                },
                notes: {
                    address: userData.address
                },
                theme: {
                    color: "#3399cc"
                },
                payment_capture: 1,
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } else if (paymentData.message === 'Amount mismatch') {
            toast.error(
                'Payment issue: Please check your currency and method, then try again.',
                { position: 'top-right' }
            );
            setLoading(false);
        } else {
            // Handle other errors
            toast.error(paymentData.message || 'An error occurred. Please try again.', { position: 'top-right' });
            setLoading(false);
        }
        } catch (error) {
            toast.error('An error occurred during payment. Please try again.', { position: 'top-right' });
            setLoading(false);
        }
    };

    const handlePaypalPayment = async (paymentMethod) => {
        setSelectedPayment(paymentMethod);
        setLoading(true);
        const res = await loadPaypalScript();
        if (!res) {
            toast.error('PAYPAL SDK failed to load. Are you online?', { position: 'top-right' });
            setLoading(false);
            return;
        }
        try {
            // Load the PayPal script dynamically
    
            // Render PayPal Buttons
            window.paypal.Buttons({
                createOrder: async () => {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}createPayPalOrder`, {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${user}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            amount: totalAmount,
                            userData,
                            products: cardData.map(product => ({
                                id: product.id,
                                quantity: product.quantity,
                                name: product.name,
                            })),
                        }),
                        withCredentials: true,
                    });
    
                    const data = await response.json();
    
                    if (response.ok && data.orderId) {
                        await reduceProductQuantity();
                        return data.orderId;
                    } else {
                        toast.error(data.message || "Failed to create PayPal order.Please try again after sometime", { position: "top-right" });
                    }
                },
                onApprove: async (data) => {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}capturePayPalPayment`, {
                            method: "POST",
                            headers: {
                                "Authorization": `Bearer ${user}`,
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ orderId: data.orderID }),
                            withCredentials: true,
                        });
    
                        const result = await response.json();
    
                        if (result.status === "COMPLETED") {
                            toast.success("Payment successful!", { position: "top-right" });
                            handlePaymentComplete();
                            dispatch(clearCart());
                        } else {
                            throw new Error("Payment verification failed.");
                        }
                    } catch (error) {
                        await restoreProductQuantities();
                        toast.error(error.message || "An error occurred during payment approval.", { position: "top-right" });
                    }
                },
                onError: (error) => {
                    toast.error("An error occurred with PayPal: " + error.message, { position: "top-right" });
                    setLoading(false);
                },
                onCancel: () => {
                    toast.info("Payment process was cancelled.", { position: "top-right" });
                    restoreProductQuantities();
                    setLoading(false);
                },
            }).render("#paypal-button-container");
    
            setLoading(false);
        } catch (error) {
            toast.error(error.message || "An error occurred while loading PayPal.", { position: "top-right" });
            setLoading(false);
        }
    };
    
    const handlePaymentSelection = (paymentMethod) => {
        setSelectedPayment(paymentMethod);
    };

    const handlePaymentOption = (paymentMethod) => {
        setSelectedPayment(null);
    }

    const calculateTotal = () => {
        return productTotal + deliveryCharge;
    };

    return (
        <div className='payment'>
            <h2>Select Payment Method</h2>
            {!selectedPayment && (
                <div className='payment-options'>
                    <button onClick={() => handlePaymentSelection('razorpay')} disabled={loading}>
                        Razorpay (India)
                    </button>
                    <div className="or-text">                    
                        <div className="line"></div>
                        <p>OR</p>
                        <div className="line"></div>
                    </div>
                    <button onClick={() => handlePaypalPayment('paypal')} disabled={loading}>
                        {loading ? (
                            <ColorRing type="TailSpin" color="#00BFFF" height={30} width={30} />
                        ) : (
                            `PayPal (International)`
                        )}
                    </button>
                </div>
            )}

            {selectedPayment === 'razorpay' && (
                <>
                    <div className='payment-options'>
                        <button onClick={handleRazorpayPayment} disabled={loading}>
                        {loading ? (
                                <ColorRing type="TailSpin" color="#00BFFF" height={30} width={30} />
                            ) : (
                                `Pay ${totalAmount.toFixed(2)}`
                            )}
                        </button>
                        <div className="or-text">                    
                            <div className="line"></div>
                            <p>OR</p>
                            <div className="line"></div>
                        </div>
                        <button onClick={handlePaymentOption} disabled={loading}>
                            Change Payment Method
                        </button>
                    </div>
                </>
            )}
            {selectedPayment === 'paypal' && (
                <div className="paypal-container">
                    <div id="paypal-button-container"></div>
                    <div className="or-text">                    
                        <div className="line"></div>
                        <p>OR</p>
                        <div className="line"></div>
                    </div>
                    <button onClick={handlePaymentOption} disabled={loading}>
                        Change Payment Method
                    </button>
                </div>
            )}
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ProductCard from '../components/product/ProductCard';
import MetaTags from '../utils/MetaTags';
import { useCountry } from '../context/CountryContext';
import {COMMON} from '../constant/constant';
import { Link,useParams } from 'react-router-dom';

export default function AllCategory() {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { selectedCountry,updateCountry } = useCountry();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}category`,{
          withCredentials: true,
        });
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, [slug,selectedCountry]);

  if (loading) {
    return (
        <div className='catloader'>
          <img src={`${COMMON}wallfleur.loading.gif`} alt='loader' />
        </div> 
      );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <Navbar />
      {data && (
        <>
          <MetaTags 
            title='All Categories'
            description='Discover our curated categories of handcrafted clay products, each featuring unique designs to suit your style and needs. Shop by category to find your perfect match!' 
            noIndex={false}
          />
          <div className='wallfleur_row'>
            <div className='breadcrums'>Home {' / All Category'} </div>
            <h1 className='heading-text'>All Category</h1>
            <ProductCard datas={data} contentType="category" />
          </div>
        </>
      )}
      <Footer />
    </>
  );
}

import React, { useState, useCallback } from 'react';
import { Navbar, Container, Form, FormControl, Button, ListGroup } from 'react-bootstrap';
import {COMMON} from '../constant/constant';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const SearchBar = ({ onClose, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const debouncedSearch = useCallback(
    _.debounce((query) => {
      if (query) {
        fetch(`${process.env.REACT_APP_API_URL}search?query=${query}`)
          .then(response => response.json())
          .then(data => {
            setSuggestions(data.slice(0, 6));
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setSuggestions([]);
          });
      } else {
        setSuggestions([]);
      }
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
  };

  return (
    <Navbar bg="white" variant="white" className="search-bar position-relative">
      <Container style={{ flexWrap: 'inherit', justifyContent: 'center',position: 'relative' }}>
        <Form className="d-flex" style={{ width: '100%', marginRight: '20px' }}>
          <FormControl
            type="search"
            placeholder="Enter Product Name"
            className="mr-2"
            value={searchQuery}
            onChange={handleInputChange}
          />
        </Form>
        <Button variant="none" style={{ border: 'none', boxShadow: 'none',fontSize:'25px' }} onClick={onClose}>
          <img src={`${COMMON}close-icon.svg`} alt="close" />
        </Button>
        {suggestions.length > 0 ? (
        <ListGroup className="suggestions-list position-absolute" style={{ width: '100%', top: '75%', zIndex: 1000, backgroundColor:'#fff',boxShadow:'0 2px 4px rgba(0, 0, 0, 0.3)' }}>
          {suggestions.map((product, index) => (
            <ListGroup.Item key={index} style={{ padding: '15px', cursor: 'pointer' }}>
              <Link to={`/product/${product.slug}`}  style={{ textDecoration: 'none', color: 'inherit' }} >
                {product.name}
              </Link>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        searchQuery && (
          <div className="no-suggest position-absolute" style={{ width: '100%', textAlign: 'center', top: '75%', zIndex: 1000, backgroundColor:'#fff',boxShadow:'0 2px 4px rgba(0, 0, 0, 0.3)',padding:'10px' }}>
            No product Found
          </div>
        )
      )}
      </Container>
    </Navbar>
  );
};

export default SearchBar;

import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { DEFAULT_IMAGE,COMMON,PRODUCT } from '../constant/constant';
import { useNavigate } from "react-router-dom";

export default function CommonSlider({ datas, countryCurrency, contentType }) {
  const navigate = useNavigate();

  const [shouldShowSlider, setShouldShowSlider] = useState(false);

  useEffect(() => {
    const updateSliderVisibility = () => {
      const width = window.innerWidth;
      const itemsCount = datas.length;
      
      const isIpadPro = /iPad|Macintosh/i.test(navigator.userAgent) && ('ontouchend' in document);

      if (isIpadPro) {
        setShouldShowSlider(itemsCount > 3);
      } else if (width >= 1024) {
        setShouldShowSlider(itemsCount > 4); 
      } else if (width >= 768) {
        setShouldShowSlider(itemsCount > 3); 
      } else {
        setShouldShowSlider(itemsCount > 2);
      }
    };

    updateSliderVisibility(); 
    window.addEventListener('resize', updateSliderVisibility);

    return () => {
      window.removeEventListener('resize', updateSliderVisibility);
    };
  }, [datas.length]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 2,
    arrows: true ,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3.2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          arrows:false,
        },
      },
    ],
  };

  const handleRedirectUrlClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div>
      {datas && datas.length > 0 ? (
        shouldShowSlider ? (
          <Slider {...settings}>
            {datas.map((data, index) => (
              <div key={index} >
                <div 
                  onClick={() => handleRedirectUrlClick(`/${contentType}/${data.slug}`)}
                  className="common-card mt-3" style={{position:'relative'}}
                >
              {data.preorder === 1 && (
                <div className="preorder-badge">
                  <img src={`${COMMON}preorder-badge.png`} alt="preorder-badge" />
                </div>
                )}
                  <div className={`prod-img ${data.quantity <= 2 || data.coming_soon === 1 ? 'sold_out' : ''}`}>
                    <img className="card-img-top lazyload" src={`${DEFAULT_IMAGE}`} data-src={`${PRODUCT}${data.image1}`} alt={`${data.name}`} />
                    <span style={{ display: data.quantity <= 2 ? 'block' : 'none' }}>Out of Stock</span>
                    <span style={{ display: data.coming_soon == 1 ? 'block' : 'none' }}>Coming soon</span>
                  </div>
                  <div className="card-body" style={{ padding: "10px 20px", fontSize: "14px",border:"0.5px solid #faefef" ,boxShadow:"0 4px 6px rgba(0, 0, 0, 0.1)" }}>
                    <p className="card-text">{data.name}</p>    
                    <p className="card-price">{data.currency}{data.price}
                  </p>    
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="product-card">
            {datas.map((data, index) => (
              <div 
                key={index}
                className="prod-card" 
                onClick={() => handleRedirectUrlClick(`/${contentType}/${data.slug}`)}
              >
                {data.preorder === 1 && (
                  <div className="preorder-badge">
                    <img src={`${COMMON}preorder-badge.png`} alt="preorder-badge" />
                  </div>
                )}
                <div className={`prod-img ${data.quantity <= 2 || data.coming_soon === 1 ? 'sold_out' : ''}`}>
                  <img className="card-top lazyload" src={`${DEFAULT_IMAGE}`} data-src={`${PRODUCT}${data.image1}`} alt={`${data.name}`} />
                  <span style={{ display: data.quantity <= 2 ? 'block' : 'none' }}>Out of Stock</span>
                  <span style={{ display: data.coming_soon == 1 ? 'block' : 'none' }}>Coming soon</span>
                </div>
                <div className="prod-card-body">
                <div className="prod-content">

                  <p className="prod-name">{data.name}</p>
                  <p className="prod-price">{data.currency}{data.price}
                  </p>
                </div>
                </div>
              </div>
            ))}
          </div>
        )
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}
